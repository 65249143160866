var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: { title: _vm.title, breadcrumbs: _vm.breadcrumbs }
      }),
      _c(
        "v-form",
        { ref: "form" },
        [
          _c(
            "v-row",
            [
              _vm._l(_vm.fields, function(field) {
                return _c(
                  "v-col",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !field.hidden,
                        expression: "!field.hidden"
                      }
                    ],
                    key: field.key,
                    class: field.classes
                  },
                  [
                    _c(
                      "app-field",
                      _vm._b(
                        {
                          on: {
                            input: function($event) {
                              return _vm.bindValue($event, field)
                            },
                            "text-changed": _vm.updateTask
                          }
                        },
                        "app-field",
                        field,
                        false
                      )
                    )
                  ],
                  1
                )
              }),
              _c(
                "v-col",
                {
                  staticClass: "float-right pt-0",
                  attrs: { lg: "2", md: "4", sm: "12", col: "12" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c("span", { staticClass: "timer" }, [
                        _vm._v(_vm._s(_vm.time))
                      ]),
                      _vm.running
                        ? _c("app-icon-btn", {
                            attrs: {
                              btnColor: "primary",
                              icon: "mdi-stop-circle",
                              iconSize: "x-large",
                              btnSize: "large",
                              tooltip: _vm.$t("actions.stop")
                            },
                            on: {
                              click: function($event) {
                                return _vm.stopTimer()
                              }
                            }
                          })
                        : _c("app-icon-btn", {
                            attrs: {
                              btnColor: "primary",
                              icon: "mdi-play-circle",
                              iconSize: "x-large",
                              btnSize: "large",
                              tooltip: _vm.timeBegan
                                ? _vm.$t("actions.resume")
                                : _vm.$t("actions.start")
                            },
                            on: {
                              click: function($event) {
                                return _vm.startTimer()
                              }
                            }
                          }),
                      _c("app-icon-btn", {
                        attrs: {
                          disabled: !_vm.running,
                          icon: "mdi-pause",
                          iconSize: "small",
                          btnSize: "small",
                          tooltip: _vm.$t("actions.pause")
                        },
                        on: {
                          click: function($event) {
                            return _vm.pauseTimer()
                          }
                        }
                      }),
                      _c("app-icon-btn", {
                        attrs: {
                          disabled: !_vm.ongoingTask.id,
                          icon: "mdi-trash-can-outline",
                          iconSize: "small",
                          btnSize: "small",
                          tooltip: _vm.$t("actions.discard")
                        },
                        on: {
                          click: function($event) {
                            return _vm.discardTime()
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            2
          )
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.worklogs.items,
          options: _vm.options,
          "server-items-length": _vm.worklogs.count,
          loading: _vm.loading,
          "no-data-text": _vm.$t("messages.emptyState", {
            entity: _vm.$tc("models.worklog.entity")
          }),
          "no-results-text": _vm.$t("messages.emptyState", {
            entity: _vm.$tc("models.worklog.entity")
          }),
          "footer-props": { itemsPerPageOptions: [10, 25, 50, 100] },
          "hide-default-header": "",
          "item-key": "id",
          "group-by": "date"
        },
        on: {
          "update:options": [
            function($event) {
              _vm.options = $event
            },
            _vm.updateOptions
          ]
        },
        scopedSlots: _vm._u(
          [
            {
              key: "group.header",
              fn: function(ref) {
                var group = ref.group
                var items = ref.items
                return [
                  _c(
                    "td",
                    {
                      staticClass: "date-header",
                      attrs: { colspan: _vm.headers.length }
                    },
                    [
                      _c("h4", [
                        _vm._v(" " + _vm._s(_vm.header(group)) + " "),
                        _c(
                          "span",
                          { staticClass: "float-right" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.totalDurationPerDay(
                                    items.map(function(item) {
                                      return item.durationInSec
                                    })
                                  )
                                ) +
                                " "
                            ),
                            items.find(function(item) {
                              return item.jiraTask && !item.isSynced
                            })
                              ? _c("app-icon-btn", {
                                  attrs: {
                                    classes: "ma-0 pa-0",
                                    icon: "mdi-sync",
                                    iconSize: "small",
                                    tooltip: _vm.$t("actions.syncAll")
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.syncTasks(
                                        items.map(function(item) {
                                          return item.id
                                        })
                                      )
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]
              }
            },
            {
              key: "item.startTime",
              fn: function(ref) {
                var item = ref.item
                return [
                  !item.isSynced
                    ? _c(
                        "v-edit-dialog",
                        {
                          attrs: { "return-value": item.startTime },
                          on: {
                            "update:returnValue": function($event) {
                              return _vm.$set(item, "startTime", $event)
                            },
                            "update:return-value": function($event) {
                              return _vm.$set(item, "startTime", $event)
                            },
                            save: function($event) {
                              return _vm.updateTaskTime(item)
                            },
                            open: function($event) {
                              return _vm.openTaskTimeDialog(item)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "input",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-row",
                                      { staticClass: "inline-edit-form" },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "pt-5 pb-0 col-6",
                                          attrs: {
                                            type: "time",
                                            label: _vm.$t(
                                              "models.worklog.attributes.startTime"
                                            )
                                          },
                                          model: {
                                            value: _vm.startDatetime,
                                            callback: function($$v) {
                                              _vm.startDatetime = $$v
                                            },
                                            expression: "startDatetime"
                                          }
                                        }),
                                        _c("v-text-field", {
                                          staticClass: "pt-5 pb-0 col-6",
                                          attrs: {
                                            label: _vm.$t(
                                              "models.worklog.attributes.endTime"
                                            ),
                                            type: "time"
                                          },
                                          model: {
                                            value: _vm.endDatetime,
                                            callback: function($$v) {
                                              _vm.endDatetime = $$v
                                            },
                                            expression: "endDatetime"
                                          }
                                        }),
                                        _c("v-date-picker", {
                                          attrs: {
                                            flat: "",
                                            "full-width": "",
                                            max: _vm
                                              .moment()
                                              .format("YYYY-MM-DD")
                                          },
                                          model: {
                                            value: _vm.date,
                                            callback: function($$v) {
                                              _vm.date = $$v
                                            },
                                            expression: "date"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.startTime) +
                              " - " +
                              _vm._s(item.endTime) +
                              " "
                          )
                        ]
                      )
                    : _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(item.startTime) +
                            " - " +
                            _vm._s(item.endTime) +
                            " "
                        )
                      ])
                ]
              }
            },
            {
              key: "item.duration",
              fn: function(ref) {
                var item = ref.item
                return [
                  !item.isSynced
                    ? _c(
                        "v-edit-dialog",
                        {
                          attrs: { "return-value": item.duration },
                          on: {
                            "update:returnValue": function($event) {
                              return _vm.$set(item, "duration", $event)
                            },
                            "update:return-value": function($event) {
                              return _vm.$set(item, "duration", $event)
                            },
                            save: function($event) {
                              return _vm.updateTaskDuration(item)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "input",
                                fn: function() {
                                  return [
                                    _c("v-text-field", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "##:##:##",
                                          expression: "'##:##:##'"
                                        }
                                      ],
                                      attrs: {
                                        label: _vm.$t(
                                          "models.worklog.attributes.duration"
                                        ),
                                        "single-line": ""
                                      },
                                      model: {
                                        value: item.duration,
                                        callback: function($$v) {
                                          _vm.$set(item, "duration", $$v)
                                        },
                                        expression: "item.duration"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [_vm._v(" " + _vm._s(item.duration) + " ")]
                      )
                    : _c("div", [_vm._v(" " + _vm._s(item.duration) + " ")])
                ]
              }
            },
            {
              key: "item.description",
              fn: function(ref) {
                var item = ref.item
                return [
                  !item.isSynced
                    ? _c(
                        "v-edit-dialog",
                        {
                          attrs: { "return-value": item.description },
                          on: {
                            "update:returnValue": function($event) {
                              return _vm.$set(item, "description", $event)
                            },
                            "update:return-value": function($event) {
                              return _vm.$set(item, "description", $event)
                            },
                            save: function($event) {
                              return _vm.updateTaskDescription(item)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "input",
                                fn: function() {
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t(
                                          "models.worklog.attributes.description"
                                        ),
                                        "single-line": ""
                                      },
                                      model: {
                                        value: item.description,
                                        callback: function($$v) {
                                          _vm.$set(item, "description", $$v)
                                        },
                                        expression: "item.description"
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "jira-link",
                              attrs: { href: item.jiraUrl, target: "blank" }
                            },
                            [_vm._v(_vm._s(item.jiraTask))]
                          ),
                          _c("span", { class: { "pl-2": item.jiraTask } }, [
                            _vm._v(_vm._s(item.description))
                          ])
                        ]
                      )
                    : _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "jira-link",
                            attrs: { href: item.jiraUrl, target: "blank" }
                          },
                          [_vm._v(_vm._s(item.jiraTask))]
                        ),
                        _c("span", { class: { "pl-2": item.jiraTask } }, [
                          _vm._v(_vm._s(item.description))
                        ])
                      ])
                ]
              }
            },
            {
              key: "item.actions",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("app-icon-btn", {
                    attrs: {
                      icon: "mdi-play",
                      iconSize: "small",
                      btnSize: "small",
                      tooltip: _vm.$t("actions.resume")
                    },
                    on: {
                      click: function($event) {
                        return _vm.resumeTask(item)
                      }
                    }
                  }),
                  item.jiraTask && !item.isSynced
                    ? _c("app-icon-btn", {
                        attrs: {
                          icon: "mdi-sync",
                          iconSize: "small",
                          btnSize: "small",
                          tooltip: _vm.$t("actions.sync")
                        },
                        on: {
                          click: function($event) {
                            return _vm.syncTasks([item.id])
                          }
                        }
                      })
                    : _vm._e(),
                  !item.isSynced
                    ? _c("app-icon-btn", {
                        attrs: {
                          icon: "mdi-trash-can-outline",
                          iconSize: "small",
                          btnSize: "small",
                          tooltip: _vm.$t("actions.delete")
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteTask(item.id)
                          }
                        }
                      })
                    : _vm._e()
                ]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }